import React from 'react'
import SbEditable from 'storyblok-react'
import {graphql, useStaticQuery} from "gatsby";
import News from "../news";

const NewsArchive = (props) => {

    const data = useStaticQuery(graphql`
    query NewsArchiveQuery {
      allStoryblokEntry(filter: {field_component: {eq: "blogPost"}, full_slug: {regex: "/^news\\//"}}, limit: 3, sort: {fields: first_published_at, order: DESC}) {
        edges {
          node {
            id
            name
            full_slug
            content
          }
        }
      }
    }
  `);

    return <SbEditable content={props.blok}>
        <News items={data.allStoryblokEntry.edges}/>
    </SbEditable>;
};

export default NewsArchive;