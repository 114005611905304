import React from 'react'
import SbEditable from 'storyblok-react'
import {Link, LinkCenteredWrapper, LinkSecondary} from "../link";

const LinkItem = (props) => {
    const {secondaryStyle, link, target, text, centered} = props.blok;
    const linkPrefix = link.cached_url.includes('http') ? '' : '/';

    const linkElement = secondaryStyle ?
        <LinkSecondary href={`${linkPrefix}${link.cached_url}`} target={target}>{text}&nbsp;&nbsp;&nbsp;
            <span>&gt;&gt;</span></LinkSecondary> :
        <Link href={`${linkPrefix}${link.cached_url}`} target={target}>{text}</Link>
    ;
    return <SbEditable content={props.blok}>
        {centered ? <LinkCenteredWrapper>{linkElement}</LinkCenteredWrapper> : linkElement}
    </SbEditable>
};

export default LinkItem;