import React from 'react'
import SbEditable from 'storyblok-react'
import {graphql, useStaticQuery} from "gatsby";
import styled from "styled-components";
import {HeadlineH3} from "./h3";
import {breakpoints} from "../../utils/responsive";
import {fontWeights} from "../../utils/styles";

const PodcastEpisode = styled.div`
  position: relative;
  margin-bottom: 5rem;
  
  @media(min-width: ${breakpoints.md}px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    
    position: relative;
    margin: 0 auto;
    padding: 5rem 0;
    max-width: 1166px;
  }
`;

const PodcastImageWrapper = styled.div`
  margin: 0 auto 3rem;
  padding: 0 2.25rem;
`;

const PodcastContentWrapper = styled.div`
  padding: 0 2.25rem;
`;

const PodcastDesktopWrapper = styled.div`
`;

const PodcastTitle = styled.p`
  font-weight: ${fontWeights.regular};
  text-align: center;
  margin-bottom: 2rem;
`;

const PodcastSummary = styled.p`
  font-weight: ${fontWeights.light};
  text-align: center;
`;

const PodcastEpisodes = (props) => {
    const data = useStaticQuery(graphql`
    query PodcastEpisodesQuery {
      allPodcastRssFeedEpisode {
        nodes {
          item {
            title
            link
            pubDate
            guid
            itunes {
              duration
              summary
              image
            }
          }
        }
      }
    }
  `);

    return <SbEditable content={props.blok}>
        {data.allPodcastRssFeedEpisode.nodes.map(node => {
            const {title} = node.item;
            const titleParts = title.split(':');
            return <PodcastEpisode key={node.item.guid}>
                <PodcastImageWrapper><img src={node.item.itunes.image} alt={node.item.title}/></PodcastImageWrapper>
                <PodcastDesktopWrapper>
                    <HeadlineH3><span>{titleParts[0]}</span></HeadlineH3>
                    <PodcastContentWrapper>
                        <PodcastTitle>{titleParts[1]}</PodcastTitle>
                        <PodcastSummary>{node.item.itunes.summary}</PodcastSummary>
                    </PodcastContentWrapper>
                </PodcastDesktopWrapper>
            </PodcastEpisode>
        })}
    </SbEditable>
};

export default PodcastEpisodes;