import React from 'react'
import SbEditable from 'storyblok-react'
import styled from "styled-components";
import {colors, fontWeights} from "../../utils/styles";

export const HeadlineH3 = styled.h2`
    position:relative;
    color: ${colors.text};
    text-transform: uppercase;
    text-align: center;
    font-weight: ${fontWeights.regular};
    font-size: 1rem;
    background: ${colors.background};
    margin: 1rem 0 2rem;
    span {
      position: relative;
      display: inline-block;
      background: ${colors.background};
      padding: 0 1rem;
      margin: 0 1rem;
      z-index: 2;
    }
    
    &:before {
      position: absolute;
      content: '';
      top: 50%;
      left: 0;
      width: 100%;
      height: 1px;
      background: ${colors.border};
      z-index: 1;    
    }
`;

const H3 = (props) => {
    return <SbEditable content={props.blok}>
        <HeadlineH3 id={props.blok.id}><span>{props.blok.text}</span></HeadlineH3>
    </SbEditable>
}

export default H3;