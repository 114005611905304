import React from 'react'
import styled from "styled-components";

const Wrapper = styled.div`
    position: relative;
    margin: 0 auto;
    padding: 0 2.25rem;
    max-width: 1166px;
`;

const MaxWidthWrapper = ({children}) => {
    return <Wrapper>{children}</Wrapper>
};

export default MaxWidthWrapper;
