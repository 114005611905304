import React from 'react'
import Components from './components.js';
import styled from "styled-components";
import SbEditable from "storyblok-react";
import {breakpoints} from "../../utils/responsive";
import MaxWidthWrapper from "../maxWidthWrapper";

const List = styled.div`
    
    @media(min-width: ${breakpoints.sm}px) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 9rem;
    }
`;

const Discography = (props) => {
    return <SbEditable content={props.blok}>
        <MaxWidthWrapper>
            <List>
                {props.blok.blocks && props.blok.blocks.map((block) => React.createElement(Components(block.component), {
                    key: block._uid,
                    blok: block
                }))}
            </List>
        </MaxWidthWrapper>
    </SbEditable>
}

export default Discography