import React from 'react'
import SbEditable from 'storyblok-react'
import {graphql, useStaticQuery} from "gatsby";
import styled from "styled-components";
import Product from "../product";
import {breakpoints} from "../../utils/responsive";

const ProductsWrapper = styled.div`
    position: relative;
    
    @media(min-width: ${breakpoints.md}px) {
    
        max-width: 900px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 2rem;
    }
`;

const Products = (props) => {
    let {tags} = props.blok;
    tags = tags.split(',');
    const data = useStaticQuery(graphql`
    {
      allStoryblokEntry(filter: {field_component: {eq: "product"}}, sort: {fields: position, order: ASC}) {
        edges {
          node {
            id
            name
            slug
            full_slug
            field_component
            tag_list
            content
          }
        }
      }
    }
  `);

    const orderedSizes = ['s', 'm', 'l', 'xl', '2xl', '3xl'];

    let products = data.allStoryblokEntry.edges.map(edge => {
        const {id, name, slug, full_slug, tag_list} = edge.node;
        const content = JSON.parse(edge.node.content);
        const {description, availableSizes, price, images, socialShirt} = content;

        if (availableSizes) {
            availableSizes.sort((a, b) => {
                return orderedSizes.indexOf(a) - orderedSizes.indexOf(b)
            });
        }

        return {
            id,
            name,
            description,
            availableSizes,
            price,
            images,
            slug,
            socialShirt,
            fullSlug: full_slug.replace('/', '-'),
            tags: tag_list,
        }
    });

    tags.forEach(tag => {
        products = products.filter(product => product.tags.includes(tag));
    });

    return <SbEditable content={props.blok}>
        <ProductsWrapper>
            {products.map(product => {
                return <Product key={product.id} {...product} />
            })}
        </ProductsWrapper>
    </SbEditable>
};

export default Products;