import React from 'react'
import Components from './components.js';
import styled from "styled-components";
import Spacer from "./spacer";
import {HeadlineH2} from "./h2";
import MaxWidthWrapper from "../maxWidthWrapper";
import moment from "moment";

const HeadlineWrapper = styled.div`
  text-align: center;
  margin-bottom: 1rem;
`;

const PublishDate = styled.p`
    font-size: .8rem;
    text-align: center;
    font-style: italic;
`;

const BlogPost = (props) => {
    const publishDate = moment(props.publishedAt);
    return <MaxWidthWrapper>
        <HeadlineWrapper><HeadlineH2>{props.title}</HeadlineH2></HeadlineWrapper>
        <PublishDate>{publishDate.format('DD.MM.YYYY')}</PublishDate>
        <Spacer blok={{height: 3}}/>
        {props.blok.body && props.blok.body.map((blok) => React.createElement(Components(blok.component), {
            key: blok._uid,
            blok: blok
        }))}
    </MaxWidthWrapper>
}

export default BlogPost