import React from 'react'
import Components from './components.js';
import styled from "styled-components";

const List = styled.div`
    text-align: center;
    a {
       margin: .5rem;
    }
`;

const LinkList = (props) => {
    return <List>
        {props.blok.links && props.blok.links.map((link) => React.createElement(Components(link.component), {
            key: link._uid,
            blok: link
        }))}
    </List>
}

export default LinkList