import {getCookie, setCookie} from "./cookie";

export const isFloat = (n) => {
    return Number(n) === n && n % 1 !== 0;
};

export const getCurrencySettingsPrefixed = (currency) => {
    const symbols = {
        'EUR': {symbol: '€', format: (price) => ` € ${price.replace('.', ',')}`, decimalSeparator: ','},
    };

    return symbols[currency];
};

export const getCurrencySettings = (currency) => {
    const symbols = {
        'EUR': {symbol: '€', format: (price) => `${price.replace('.', ',')} €`, decimalSeparator: ','},
    };

    return symbols[currency];
};

export const testPrice = (price) => {
    let decimalPrice = (price / 100);
    if (isFloat(decimalPrice)) {
        decimalPrice = decimalPrice.toFixed(2);
    }
    return String(decimalPrice);
}

export const formatPrice = (price, currency = 'EUR', currencyPrefixed = false) => {
    const currencySettings = currencyPrefixed ? getCurrencySettingsPrefixed(currency) : getCurrencySettings(currency);
    let decimalPrice = (price / 100);
    if (isFloat(decimalPrice)) {
        decimalPrice = decimalPrice.toFixed(2);
    }
    decimalPrice = String(decimalPrice);
    if (currencySettings) {
        return currencySettings.format(decimalPrice);
    }
    return `${decimalPrice.replace('.', ',')} ${currency}`;
};

export const addProductToCart = (sku, amount) => {
    const productsInCart = getCookie('cart') || {};
    productsInCart[sku] = amount;
    setCookie('cart', productsInCart);
};

export const getCart = () => getCookie('cart');