import React from 'react'
import MaxWidthWrapper from "../maxWidthWrapper";
import styled from "styled-components";
import {colors} from "../../utils/styles";
import {LinkButton} from "../link";
import {breakpoints} from "../../utils/responsive";

const FormFieldWrapper = styled.div`
    margin-bottom: 2rem;
    @media(min-width: ${breakpoints.md}px) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
`;

const FieldLabel = styled.label`
  display: block;
`;

const TextInput = styled.input`
  background: ${colors.backgroundLighter};
  color: ${colors.text};
  border: 1px solid ${colors.border};
  padding: .5rem;
  border-radius: 4px;
  width: 90%;
`;

const InterestListItem = styled.li`
  margin-bottom: .5rem;
  input {
    margin-right: 1rem;
  }
`;

const NewsletterSubscribeForm = () => {
    return <MaxWidthWrapper>
        <div id="mc_embed_signup">
            <form
                action="https://band.us19.list-manage.com/subscribe/post?u=a3d2b85f199c73f38e780d86f&amp;id=82cbaaeded"
                method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate"
                target="_blank" noValidate>
                <div id="mc_embed_signup_scroll">
                    <FormFieldWrapper>
                        <div className="mc-field-group">
                            <FieldLabel htmlFor="mce-EMAIL">E-Mail-Adresse <span
                                className="asterisk">*</span></FieldLabel>
                            <TextInput type="email" name="EMAIL" className="required email" id="mce-EMAIL"/>
                        </div>
                        <div className="mc-field-group">
                            <FieldLabel htmlFor="mce-FNAME">Vorname <span className="asterisk">*</span></FieldLabel>
                            <TextInput type="text" name="FNAME" className="required" id="mce-FNAME"/>
                        </div>
                        <div className="mc-field-group">
                            <FieldLabel htmlFor="mce-LNAME">Nachname </FieldLabel>
                            <TextInput type="text" name="LNAME" className="" id="mce-LNAME"/>
                        </div>
                    </FormFieldWrapper>
                    <FormFieldWrapper>
                        <p><strong>Für was interessierst du dich? </strong></p>
                        <ul>
                            <InterestListItem><label htmlFor="mce-group[13100]-13100-0"><input type="checkbox" value="1"
                                                                                               defaultChecked={true}
                                                                                               name="group[13100][1]"
                                                                                               id="mce-group[13100]-13100-0"/>Die
                                Punkrock-Polizei – Der AOP-Podcast</label></InterestListItem>
                            <InterestListItem><label htmlFor="mce-group[13100]-13100-1"><input type="checkbox" value="2"
                                                                                               defaultChecked={true}
                                                                                               name="group[13100][2]"
                                                                                               id="mce-group[13100]-13100-1"/>Alles
                                andere</label></InterestListItem>
                        </ul>
                        <LinkButton type="submit" id="mc-embedded-subscribe">Anmelden</LinkButton>
                    </FormFieldWrapper>
                    {/*<p><a href="https://us19.campaign-archive.com/home/?u=a3d2b85f199c73f38e780d86f&id=82cbaaeded" title="View previous campaigns">View previous campaigns.</a></p>*/}
                    <div id="mce-responses" className="clear">
                        <div className="response" id="mce-error-response" style={{display: 'none'}}/>
                        <div className="response" id="mce-success-response" style={{display: 'none'}}/>
                    </div>
                    <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input type="text"
                                                                                                   name="b_a3d2b85f199c73f38e780d86f_82cbaaeded"
                                                                                                   tabIndex="-1"
                                                                                                   value=""/></div>
                </div>
            </form>
        </div>
    </MaxWidthWrapper>
};

export default NewsletterSubscribeForm;