import React from 'react'
import SbEditable from 'storyblok-react'
import styled from "styled-components";
import MaxWidthWrapper from "../maxWidthWrapper";
import {colors} from "../../utils/styles";
import {breakpoints} from "../../utils/responsive";

const HeadlineWrapper = styled.div`
  text-align: center;
`;
export const HeadlineH2 = styled.h2`
  display: inline-block;
  color: ${colors.headline};
  text-transform: uppercase;
  text-align: center;
  font-family: 'Bebas Neue', cursive;
  font-size: 1.28rem;
  
  &:after {
    content: '';
    background-image: url("/assets/h2-underline.svg");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 3px;
    position: relative;
    display: block;
  }
  
  @media(min-width: ${breakpoints.md}px) {
    font-size: 2.8571428571rem; 
    line-height: 1;
    &:after {
      height: 10px;
    }   
  }
`;

const H2 = (props) => {
    return <SbEditable content={props.blok}>
        <MaxWidthWrapper>
            <HeadlineWrapper>
                <HeadlineH2 id={props.blok.id}>{props.blok.text}</HeadlineH2>
            </HeadlineWrapper>
        </MaxWidthWrapper>
    </SbEditable>
};

export default H2;