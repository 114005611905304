import React from 'react'
import SbEditable from 'storyblok-react'
import styled from "styled-components";
import MaxWidthWrapper from "../maxWidthWrapper";

const QuoteWrapper = styled.blockquote`
    padding: 4rem 0;
    text-align: center;
`;

const Quote = (props) => {
    return <SbEditable content={props.blok}>
        <MaxWidthWrapper>
            <QuoteWrapper>{props.blok.text}</QuoteWrapper>
        </MaxWidthWrapper>
    </SbEditable>
};

export default Quote;