import React from 'react'
import styled from "styled-components";
import ProductWithSize from "./productWithSize";
import {getStaticImageUrlPrefix, imageFormats} from "../utils/image";
import {formatPrice, testPrice} from "../utils/shop";
import {colors, fontWeights} from "../utils/styles";
import {LinkButton} from "./link";
import ReactMarkdown from "react-markdown/with-html";

export const ProductWrapper = styled.div`
    position: relative;
    margin-bottom: 3rem;
    padding: 0 2.25rem;
`;

export const ProductImageWrapper = styled.div`
  padding: 2rem 2rem 1rem;
  background: url("/assets/bg-product.svg") no-repeat center;
  background-size: contain;
`;

export const ProductTitlePriceWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ProductTitle = styled.h4`
  font-family: 'Bebas Neue', cursive;
  font-size: 1.28rem;
  line-height: 1.6428571429rem;
  color: ${colors.link};
  margin-right: .25rem;
`;

export const ProductPrice = styled.span`
  font-family: 'Bebas Neue', cursive;
  font-size: 1.28rem;
  line-height: 1.6428571429rem;
`;

export const ProductDescription = styled.div`
  padding: 0 2.25rem;
  text-align: center;
  font-weight: ${fontWeights.light};
`;

export const ProductButtonWrapper = styled.div`
  margin: 2rem 0;
  text-align: center;
`;

const Product = (props) => {
    const {images} = props;
    const mainImageUrl = images.length > 0 ? getStaticImageUrlPrefix(images[0].filename, imageFormats.standard) : null;

    if (props.availableSizes && props.availableSizes.length > 0) {
        return <ProductWithSize {...props} mainImageUrl={mainImageUrl}/>
    } else {
        return <ProductWrapper id={props.fullSlug}>
            {mainImageUrl && <ProductImageWrapper><img alt={props.name} src={mainImageUrl}/></ProductImageWrapper>}
            <ProductTitlePriceWrapper>
                <ProductTitle>{props.name}</ProductTitle>
                <ProductPrice>{formatPrice(props.price)}</ProductPrice>
            </ProductTitlePriceWrapper>
            <ProductDescription>
                {props.description && <ReactMarkdown source={props.description}/>}
            </ProductDescription>
            <ProductButtonWrapper>
                <LinkButton className="snipcart-add-item"
                            data-item-url={`https://www.aop.band/shop/`}
                            data-item-id={props.slug}
                            data-item-price={testPrice(props.price)}
                            data-item-name={props.name}
                            data-item-image={mainImageUrl}>In den Warenkorb</LinkButton>
            </ProductButtonWrapper>
        </ProductWrapper>
    }

};

export default Product;