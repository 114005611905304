import React from 'react'
import SbEditable from 'storyblok-react'
import {graphql, useStaticQuery} from "gatsby";
import styled from "styled-components";
import {Link, LinkCenteredWrapper} from "../link";
import ReactMarkdown from "react-markdown/with-html";
import MaxWidthWrapper from "../maxWidthWrapper";
import {breakpoints} from "../../utils/responsive";
import Spacer from "./spacer";

const PodcastTeaserWrapper = styled.div`
  @media(min-width: ${breakpoints.md}px) {
    display: grid;
    grid-template-columns: 1fr 28rem;
    grid-column-gap: 2rem;
  }
`;


const PodcastTextWrapper = styled.div`
  margin-bottom: 3rem;
  text-align: center;
  display: flex;
  align-items: center; 
  p, ul, ol {
    margin-bottom: 1.5rem;
  }
`;

const PodcastImageWrapper = styled.div`
  position: relative;
  margin-bottom: 3rem;
  width: calc(80vw + 2rem);
  height: calc(80vw + 2rem);
  @media(min-width: ${breakpoints.md}px) {
    width: calc(40vw + 2rem);
    height: calc(40vw + 2rem);
  }
  @media(min-width: ${breakpoints.lg}px) {
    height: 27rem;
    width: 27rem;
  }
`;

const PodcastImage = styled.div`
position: absolute;
  width: 80vw;
  height: 80vw;
  &.img-0 { 
    left: 0;
    top: 0;  
  }
  &.img-1 {
    left: 1rem;
    top: 1rem;
  }
  &.img-2 {
    left: 2rem;
    top: 2rem;
  }
  
  @media(min-width: ${breakpoints.md}px) {
    height: 40vw;
    width: 40vw;
  }
  @media(min-width: ${breakpoints.lg}px) {
    height: 25rem;
    width: 25rem;
  }
`;

const PodcastImageElement = styled.img`
`;


const PodcastTeaser = (props) => {
    const data = useStaticQuery(graphql`
    query PodcastTeaserImageQuery {
      allPodcastRssFeedEpisode(limit: 3, sort: {fields: item___isoDate, order: DESC}) {
        nodes {
          item {
            title
            link
            pubDate
            guid
            itunes {
              duration
              summary
              image
            }
          }
        }
      }
    }
  `);

    return <SbEditable content={props.blok}>
        <MaxWidthWrapper>
            <PodcastTeaserWrapper>
                <PodcastImageWrapper>
                    {data.allPodcastRssFeedEpisode.nodes.map((node, ix) => {
                        return <PodcastImage key={`podcastImage${ix}`} className={`img-${ix}`}>
                            <PodcastImageElement src={node.item.itunes.image} alt={node.item.title}/>
                        </PodcastImage>
                    })}
                </PodcastImageWrapper>
                <PodcastTextWrapper>
                    <div>
                        <ReactMarkdown source={props.blok.text}/>
                        <Spacer blok={{height: 3}}/>
                        <LinkCenteredWrapper><Link href={'/die-punkrock-polizei/'}>Jetzt
                            anhören</Link></LinkCenteredWrapper>
                    </div>
                </PodcastTextWrapper>
            </PodcastTeaserWrapper>
        </MaxWidthWrapper>
    </SbEditable>
};

export default PodcastTeaser;