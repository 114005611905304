import React from 'react'
import {formatPrice, testPrice} from "../utils/shop";
import {
    ProductButtonWrapper,
    ProductDescription,
    ProductImageWrapper,
    ProductPrice,
    ProductTitle,
    ProductTitlePriceWrapper,
    ProductWrapper
} from "./product";
import {LinkButton} from "./link";
import ReactMarkdown from "react-markdown/with-html";


const ProductWithSize = (props) => {
    const {name, slug, fullSlug, availableSizes, price, mainImageUrl, description, socialShirt = false} = props;
    return <ProductWrapper id={fullSlug}>

        {mainImageUrl && <ProductImageWrapper><img alt="" src={mainImageUrl}/></ProductImageWrapper>}
        <ProductTitlePriceWrapper>
            <ProductTitle>{props.name}</ProductTitle>
            <ProductPrice>{formatPrice(props.price)}</ProductPrice>
        </ProductTitlePriceWrapper>
        <ProductDescription>
            <p>Verfügbare Größen: {availableSizes.join(', ').toUpperCase()}</p>
            {description && <ReactMarkdown source={description}/>}
        </ProductDescription>
        <ProductButtonWrapper>
            <LinkButton className="snipcart-add-item"
                        data-item-url={`https://www.aop.band/shop/`}
                        data-item-id={slug}
                        data-item-price={testPrice(price)}
                        data-item-name={name}
                        data-item-image={mainImageUrl}
                        data-item-custom1-name="Größe"
                        data-item-custom1-options={availableSizes.join('|').toUpperCase()}
                        data-item-custom2-name={socialShirt ? "Sozial-Shirt für Obdachlose" : null}
                        data-item-custom2-options={socialShirt ? "Nein, danke|Ja, bitte ein Shirt spenden[+2.50]" : null}
            >In den Warenkorb</LinkButton>
        </ProductButtonWrapper>
    </ProductWrapper>;


};

export default ProductWithSize;