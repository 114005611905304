import styled from "styled-components";
import {colors} from "../utils/styles";


export const LinkCenteredWrapper = styled.div`
  text-align: center;
`;

export const Link = styled.a`
    display: inline-block;
    border: 1px solid ${colors.border};
    border-radius: 18px;
    padding: .5rem 1.25rem;
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;
    &:hover {
       color: #fff;
       text-decoration: underline;
    }
`;

export const LinkSecondary = styled.a`
    display: inline-block;
    padding: .5rem 1.25rem;
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;
    &:hover {
       color: #fff;
       text-decoration: underline;
    }
    span {
      color: ${colors.link};
    }
`;

export const LinkButton = styled.button`
    display: inline-block;
    border: 1px solid ${colors.border};
    border-radius: 18px;
    padding: .5rem 1.25rem;
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;
    background: none;
    &:hover {
       color: #fff;
       text-decoration: underline;
    }
`;