import Page from './page'
import ComponentNotFound from './component_not_found'
import YoutubeVideo from "./youtube-video";
import Spacer from "./spacer";
import PodcastEpisodes from "./podcastEpisodes";
import PageIntro from "./page-intro";
import BlogPost from "./blogPost";
import Content from "./content";
import LinkItem from "./link-item";
import LinkList from "./link-list";
import NewsArchive from "./newsArchive";
import Discography from "./discography";
import DiscographyItem from "./discographyItem";
import Products from "./products";
import H2 from "./h2";
import Quote from "./quote";
import FullWidthImage from "./fullWidthImage";
import H3 from "./h3";
import NewsPage from "./newsPage";
import PodcastTeaser from "./podcastTeaser";
import NewsletterSubscribeForm from "./newsletterSubscribeForm";

const ComponentList = {
    blogPost: BlogPost,
    content: Content,
    discography: Discography,
    discographyItem: DiscographyItem,
    fullWidthImage: FullWidthImage,
    h2: H2,
    h3: H3,
    linkItem: LinkItem,
    linkList: LinkList,
    newsArchive: NewsArchive,
    newsletterSubscribeForm: NewsletterSubscribeForm,
    newsPage: NewsPage,
    page: Page,
    pageIntro: PageIntro,
    podcastTeaser: PodcastTeaser,
    podcastEpisodes: PodcastEpisodes,
    products: Products,
    quote: Quote,
    spacer: Spacer,
    youtubeVideo: YoutubeVideo,
};

const Components = (type) => {
    if (typeof ComponentList[type] === 'undefined') {
        return ComponentNotFound
    }
    return ComponentList[type]
};

export default Components;