import React from 'react'
import styled from "styled-components";
import SbEditable from "storyblok-react";
import {fontWeights} from "../../utils/styles";
import {Link, LinkCenteredWrapper} from "../link";
import moment from "moment";

const Item = styled.div`
    padding: 3rem 0;
`;

const ItemImage = styled.img`
    margin-bottom: .25rem;
`;

const ItemTitle = styled.h4`
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: ${fontWeights.bold};
    text-align: center;
`;

const ReleaseDate = styled.p`
    font-size: 1rem;
    font-weight: ${fontWeights.light};
    margin-bottom: 1rem;
    text-align: center;
`;

const ReleasePlayer = styled.iframe`
    margin-bottom: 1rem;
`;

const DiscographyItem = (props) => {
    const {blok} = props;
    const releaseDate = moment(blok.releaseDate);
    let productSlug = blok.product ? blok.product.cached_url : null;
    return <SbEditable content={blok}>
        <Item>
            <ItemTitle>{blok.title}</ItemTitle>
            <ReleaseDate>Jahr der Veröffentlichung: {releaseDate.format('YYYY')}</ReleaseDate>
            <ItemImage alt={`Cover ${blok.title}`} src={blok.cover}/>
            <ReleasePlayer src={`https://open.spotify.com/embed/album/${blok.spotifyReleaseId}`} width="100%"
                           height="80"
                           frameBorder="0" allowtransparency="true" allow="encrypted-media"/>
            {productSlug && <LinkCenteredWrapper>
                <Link href={'/shop/#' + productSlug.replace('/', '-')}>Jetzt kaufen</Link>
            </LinkCenteredWrapper>}
        </Item>
    </SbEditable>
}

export default DiscographyItem