import React from "react";
import styled from "styled-components"
import MaxWidthWrapper from "../maxWidthWrapper";

const VideoContainer = styled.div`
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0;
    height: 0;
    overflow: hidden;
`;

const Video = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
`;

const YoutubeVideo = (props) => (
    <MaxWidthWrapper>
        <VideoContainer>
            <Video src={`https://www.youtube-nocookie.com/embed/${props.blok.video}?controls=0`}
                   frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                   allowFullScreen/>
        </VideoContainer>
    </MaxWidthWrapper>
);

export default YoutubeVideo;