export const imageFormats = {
    standard: {
        format: '500x0',
        prefix: 'fit-in',
    },
    imageGalleryDetail: {
        format: '800x600',
        prefix: 'fit-in',
        suffix: 'filters:fill(transparent):format(png)',
    },
    imageGalleryThumb: {
        format: '250x250',
        suffix: 'filters:focal(0x0:250x250):quality(90)',
    },
};

export const getStaticImageUrlPrefix = (imageUrl, imageFormat = null) => {
    if (imageFormat) {
        const imageService = '//img2.storyblok.com/';
        const path = imageUrl.replace('//a.storyblok.com', '');

        let imageUrlParts = [
            imageService,
        ];

        if (imageFormat.prefix) {
            imageUrlParts.push(imageFormat.prefix);
        }

        imageUrlParts.push(imageFormat.format);

        if (imageFormat.suffix) {
            imageUrlParts.push(imageFormat.suffix);
        }

        imageUrlParts.push(path);

        return '/' + imageUrlParts.join('/').replace(/\/\//gi, '/');
    }
    return `${imageUrl}`;
};