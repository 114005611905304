import React from 'react'
import SbEditable from 'storyblok-react'
import styled from "styled-components";
import Img from 'gatsby-image';
import {getFluidGatsbyImage} from 'gatsby-storyblok-image';

const ImageWrapper = styled.div`
  position: relative;
  &.with-gradient {
    &:after {
      content: '';
      position: absolute;
      height: 20%;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(180deg, rgba(48,28,61,0) 0%, rgba(48,28,61,1) 100%);    
    }
  }
`;

const FullWidthImage = (props) => {
    const fluidProps = getFluidGatsbyImage(props.blok.image, {
        maxWidth: 900
    });

    return <SbEditable content={props.blok}>
        <ImageWrapper className={props.blok.addGradientToBackgroundColor ? 'with-gradient' : null}>
            <Img fluid={fluidProps} />
        </ImageWrapper>
    </SbEditable>;
};

export default FullWidthImage;