import React from 'react'
import SbEditable from 'storyblok-react'
import styled from "styled-components";

const Headline = styled.h1`
    text-align: center;
`;

const Text = styled.p`
    text-align: center;
    font-style: italic;
`;

const PageIntro = (props) => (
    <SbEditable content={props.blok}>
        <Headline>{props.blok.headline}</Headline>
        <Text>{props.blok.text}</Text>
    </SbEditable>
)

export default PageIntro;