import React from 'react'
import ReactMarkdown from "react-markdown/with-html";
import styled from "styled-components";
import {breakpoints} from "../utils/responsive";
import MaxWidthWrapper from "./maxWidthWrapper";
import {colors, fontWeights} from "../utils/styles";

const NewsWrapper = styled.div`
  a {
    color: ${colors.link};
  }
  @media(min-width: ${breakpoints.sm}px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;
  }
`;


const NewsItem = styled.div`
  text-align: center;
  margin-bottom: 3rem;
  
  &:nth-child(3) {
    display: none;
  }
  
  @media(min-width: ${breakpoints.md}px) {
    text-align: left;
    &:nth-child(3) {
      display: block;
    }    
  }
`;

const NewsItemHeadline = styled.h3`
  font-size: 1rem;
  margin-bottom: 1rem;
  font-weight: ${fontWeights.regular};
`;

const NewsItemContent = styled.div`
  margin-bottom: 1rem;
  font-weight: ${fontWeights.light};
`;


const News = ({items}) => {

    return <MaxWidthWrapper>
        <NewsWrapper>
            {items.map(edge => {
                const content = JSON.parse(edge.node.content);
                return <NewsItem key={`news${edge.node.id}`}>
                    <NewsItemHeadline>{edge.node.name}</NewsItemHeadline>
                    <NewsItemContent><ReactMarkdown source={content.excerptText}/></NewsItemContent>
                    <p><a href={`/${edge.node['full_slug']}/`}>weiterlesen ...</a></p>
                </NewsItem>
            })}
        </NewsWrapper>
    </MaxWidthWrapper>;
};

export default News;