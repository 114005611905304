import React from 'react'
import SbEditable from 'storyblok-react'
import ReactMarkdown from "react-markdown/with-html";
import styled from "styled-components";
import {breakpoints} from "../../utils/responsive";
import MaxWidthWrapper from "../maxWidthWrapper";

const ContentWrapper = styled.div`
  text-align: center;
  p, ul, ol {
    margin-bottom: 1.5rem;
  }
  
  @media(min-width: ${breakpoints.md}px) {
    max-width: 600px;
    margin: 0 auto;
  }
`;
const Content = (props) => {

    return <SbEditable content={props.blok}>
        <MaxWidthWrapper>
            <ContentWrapper>
                <ReactMarkdown source={props.blok.text}/>
            </ContentWrapper>
        </MaxWidthWrapper>
    </SbEditable>;
};

export default Content;